<template>
  <div>
    <div>
      <SmallNav />
      <div class="">
        <div class="container pt-3">
          <div class="safe_text_holder">
            <h4 class="safe_text">
              <img src="../assets/images/safe.svg" alt="safe" />
              <span>SAFE</span>
            </h4>
            <div class="projects_link">
              <h6 class="btn">
                <img src="../assets/images/house-safe.svg" alt="projects" />
                Projects
              </h6>
            </div>
          </div>

          <div class="safe_type_holder">
            <div class="container">
              <div class="row flip_row">
                <div class="col-md-6">
                  <!-- @mouseenter="activeSafeYellow = 'yellow'"
                    @mouseleave="activeSafeYellow = 'white'" -->
                  <div
                    class="safe_holder_options orange"
                    :class="[
                      activeSafeYellow == 'yellow' ? 'addedClassGold' : 'white'
                    ]"
                    @click="selectSafe('yellow')"
                    @mouseenter="mouseEffectIn('yellow')"
                    @mouseleave="mouseEffectOut('white')"
                  >
                    <img
                      v-if="activeSafeYellow == 'white'"
                      src="../assets/images/orange-safe.svg"
                      alt="safe"
                    />
                    <img
                      v-if="activeSafeYellow == 'yellow'"
                      src="../assets/images/medium-safe.svg"
                      alt="safe"
                    />
                    <p>Gold Safe</p>
                  </div>
                </div>
                <div class="col-md-6" v-if="!safeOption && safeState != 'upgradeToGold'">
                  <!-- @mouseenter="activeSafeBlue = 'blue'"
                    @mouseleave="activeSafeBlue = 'white'" -->
                  <div
                    @click="selectSafe('blue')"
                    @mouseenter="mouseEffectIn2('blue')"
                    @mouseleave="mouseEffectOut2('white')"
                    class="safe_holder_options blue"
                    :class="[
                      activeSafeBlue == 'blue' ? 'addedClassBlue' : 'white'
                    ]"
                  >
                    <img
                      v-show="activeSafeBlue == 'white'"
                      src="../assets/images/blue-safe.svg"
                      alt="safe"
                    />
                    <img
                      v-show="activeSafeBlue == 'blue'"
                      src="../assets/images/medium-safe.svg"
                      alt="safe"
                    />
                    <p>Blue Safe</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="option_details">
                <div id="yellow_safe" class="">
                  <div class="row">
                    <!-- For Organization safe begins -->
                    <div
                      class="form-group bvn_input col-md-6"
                      v-if="safeOption"
                    >
                      <label for="exampleInputPassword1">BVN</label>
                      <input
                        type="number"
                        class="form-control"
                        id="bvn"
                        v-model="bvn"
                        placeholder="Please provide your BVN to continue"
                        min="1"
                        max="11"
                        maxlength="11"
                      />
                      <!-- :disabled="diableBvnInput" -->
                      <!-- verifyBvn -->

                      <!-- bvnCheck2 -->
                      <!-- saveCookiesForBvn -->
                      <button class="btn" @click="bvnCheck2" v-if="bvn != ''">
                        Validate
                        <i
                          class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                          v-if="validateLoader"
                        ></i>
                      </button>
                    </div>
                    <!-- For Organization safe ends -->

                    <!-- <p>type: {{ activateInput }}</p> -->
                    <div
                      class="form-group bvn_input col-md-6"
                      v-if="typeOfSafe == 'yellow' && !safeOption"
                    >
                      <label for="exampleInputPassword1">BVN</label>
                      <input
                        type="number"
                        class="form-control"
                        id="bvn"
                        v-model="bvn"
                        placeholder="Please provide your BVN to continue"
                        min="1"
                        max="11"
                        maxlength="11"
                      />
                      <!-- :disabled="diableBvnInput" -->
                      <!-- verifyBvn -->

                      <!-- bvnCheck2 -->
                      <!-- saveCookiesForBvn -->
                      <button class="btn" @click="bvnCheck2" v-if="bvn != ''">
                        Validate
                        <i
                          class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                          v-if="validateLoader"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <form
                    class="yellow_safe_user_data"
                    v-if="validBvn && typeOfSafe == 'yellow' && safeOption"
                  >
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="businesName">Organisation Name</label>
                        <input
                          v-model="bizName"
                          type="text"
                          class="form-control"
                          id="businesName"
                          placeholder="Company's Name"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="bizEmail">Email Address</label>
                        <input
                          v-model="bizEmail"
                          type="email"
                          class="form-control"
                          id="bizEmail"
                          aria-describedby="emailHelp"
                          placeholder="Email"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="mobile_num">Phone</label>
                        <input
                          v-model="bizPhone"
                          type="number"
                          class="form-control"
                          id="mobile_num"
                          placeholder="phone"
                        />
                      </div>
                    </div>
                    <div class="activate_safe">
                      <button
                        class="btn"
                        @click.prevent="activateOrgansationSafe"
                      >
                        <span v-if="!activateLoader2">Activate</span>
                        <i
                          class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                          v-if="activateLoader2"
                        ></i>
                        <img src="../assets/images/safe.svg" alt="safe" />
                      </button>
                    </div>
                  </form>

                  <form
                    class="yellow_safe_user_data"
                    v-if="validBvn && typeOfSafe == 'yellow' && !safeOption"
                  >
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="fname">First Name</label>
                        <input
                          v-model="firstName"
                          type="text"
                          class="form-control"
                          id="fname"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="surname">Surname</label>
                        <input
                          type="text"
                          class="form-control"
                          id="surname"
                          v-model="lastName"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="mname">Middle Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="mname"
                          v-model="middleName"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="email">Email Address</label>
                        <input
                          v-model="email"
                          type="email"
                          class="form-control"
                          id="email"
                          aria-describedby="emailHelp"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="phone">Phone</label>
                        <input
                          v-model="phone"
                          type="text"
                          class="form-control"
                          id="phone"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="phone">Date of Birth</label>
                        <input
                          v-model="dob"
                          type="text"
                          class="form-control"
                          id="dob"
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-4">
                        <label for="phone">Gender</label>
                        <input
                          v-model="gender"
                          type="text"
                          class="form-control"
                          id="gender"
                          disabled
                        />
                      </div>
                      <div class="form-group col-md-8">
                        <label for="mname">Address</label>
                        <input
                          v-model="address"
                          type="text"
                          class="form-control"
                          id="address"
                          :disabled="!activateAddress"
                        />
                      </div>
                    </div>
                    <div class="activate_safe">
                      <button class="btn" @click.prevent="activateGoldSafe">
                        <span v-if="!activateLoader">Activate</span>
                        <i
                          class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                          v-if="activateLoader"
                        ></i>
                        <img src="../assets/images/safe.svg" alt="safe" />
                      </button>
                    </div>
                  </form>
                </div>
                <div class="blue_safe" v-if="typeOfSafe == 'blue'">
                  <form class="yellow_safe_user_data">
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="fname2">First Name</label>
                        <input
                          v-model="fname"
                          type="text"
                          class="form-control"
                          id="fname2"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="surname2">Surname</label>
                        <input
                          type="text"
                          v-model="lname"
                          class="form-control"
                          id="surname2"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="mname2">Middle Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="mname2"
                          v-model="oname"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="phone2">Phone Number</label>
                        <input
                          v-model="nphone"
                          type="number"
                          class="form-control"
                          id="phone2"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="email2">Email Address</label>
                        <input
                          v-model="nemail"
                          type="email"
                          class="form-control"
                          aria-describedby="emailHelp"
                          id="email2"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="dob2">Date of birth</label>
                        <input
                          v-model="ndob"
                          type="date"
                          class="form-control"
                          id="dob2"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="mname">Gender</label>
                        <select
                          id="inputState"
                          class="form-control"
                          v-model="ngender"
                        >
                          <option selected disabled value="">Choose...</option>

                          <option :selected="ngender == 'male'" value="male"
                            >Male</option
                          >
                          <option :selected="ngender == 'female'" value="female"
                            >Female</option
                          >
                        </select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="naddress">Address</label>
                        <input
                          v-model="naddress"
                          type="text"
                          class="form-control"
                          id="naddress"
                        />
                      </div>
                    </div>
                    <div class="activate_safe2">
                      <button class="btn" @click.prevent="activateBlueSafe">
                        <span v-if="!activateLoader">Activate</span>
                        <i
                          class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                          v-if="activateLoader"
                        ></i>
                        <img src="../assets/images/safe.svg" alt="safe" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
// import Footer from "../views/FooterMain2.vue";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";
import moment from "moment";
import CryptoJS from "crypto-js";

export default {
  name: "SafeActivation",
  components: {
    SmallNav
    // Footer,
  },
  data() {
    return {
      safeOption: false,
      activateLoader: false,
      activateLoader2: false,
      validateLoader: false,
      validBvn: false,
      noBvn: false,
      activateInput: false,
      activeSafeBlue: "white",
      activeSafeYellow: "white",
      typeOfSafe: "",
      bvndata: {},
      bvn: "",
      ticket: "",
      diableBvnInput: false,
      activateAddress: false,
      firstName: "",
      lastName: "",
      middleName: "",
      gender: "",
      phone: "",
      dob: "",
      email: "",
      address: "",
      fname: "",
      lname: "",
      oname: "",
      ngender: "",
      nphone: "",
      ndob: "",
      nemail: "",
      naddress: "",
      bizName: "",
      bizPhone: "",
      bizEmail: "",
      customerIdentifier: "",
      beneficiaryAccount: "",
      squadaccountno: "",
      userId: "",
      user: "",
      safeState: "",
      url: "",
      genderArray: [
        {
          name: "Male",
          value: "male",
          id: 1
        },
        {
          name: "Female",
          value: "female",
          id: 2
        }
      ],
      pvtData: "",
      secret: "123#$%",
      encData: "",
      decryptBvn: "",
      cookiesData: ""
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    this.typeOfSafe = localStorage.getItem("safeType");
    this.safeState = localStorage.getItem("upgradeSafe");
    this.bizEmail = this.user.email;

    if (this.user.accounttype != "individual") {
      this.safeOption = true;
    }

    if (this.typeOfSafe == "yellow") {
      this.activeSafeYellow = "yellow";
    }
    if (this.typeOfSafe == "blue") {
      this.activeSafeBlue = "blue";
      this.noBvn = true;
      this.fname = this.user.firstname;
      this.lname = this.user.lastname;
      this.oname = this.user.othernames;
      this.nemail = this.user.email;
      this.nphone = this.user.phone;
      this.naddress = this.user.address;
      this.ndob = this.user.dob;
      this.ngender = this.user.gender;
    }

    if (this.safeState != "upgradeToGold") {
      this.url = "registerforsafe";
    } else {
      this.url = "upgradesafetogold";
    }
  },
  methods: {
    encryptData() {
      if (this.bvn.length) {
        // hash the name with any algorithm
        const data = CryptoJS.AES.encrypt(this.bvn, this.secret).toString();

        this.encData = data;
        // console.log("encrypted data", data);
        this.saveCookiesForBvn();
        // display the encrypted data
        this.getEncryptedData();
      }
    },

    async decryptData() {
      this.cookiesData = this.$cookies.get("_sheltaSafeData");
      // get data from cookie
      // this.encryptData();
      const secretData = this.cookiesData.bvn;
      // console.log("secrete bvn", secretData);
      // decrypt the data and convert to string
      this.decryptBvn = await CryptoJS.AES.decrypt(
        secretData,
        this.secret
      ).toString(CryptoJS.enc.Utf8);
    },
    getEncryptedData() {
      // get the data from localStorage or send placeholder text
      this.decryptData();
      // this.encData = localStorage.getItem("secretData") || "No value present";
    },
    selectSafe(safe) {
      localStorage.setItem("safeType", safe);
      this.typeOfSafe = localStorage.getItem("safeType");
      if (this.typeOfSafe == "yellow") {
        this.activeSafeYellow = "yellow";
        this.mouseEffectOut2();
      }
      if (this.typeOfSafe == "blue") {
        this.activeSafeBlue = "blue";
        this.mouseEffectOut("yellow");
        this.fname = this.user.firstname;
        this.lname = this.user.lastname;
        this.oname = this.user.othernames;
        this.nemail = this.user.email;
        this.nphone = this.user.phone;
        this.naddress = this.user.address;
        this.ndob = this.user.dob;
        this.ngender = this.user.gender;
      }
    },
    mouseEffectIn(color) {
      this.activeSafeYellow = color;
      // this.validBvn = false;
      // console.log("color yellow safe", this.activeSafeYellow);
    },
    mouseEffectOut(color) {
      if (this.typeOfSafe == "yellow") {
        this.activeSafeYellow = "yellow";
      } else {
        this.activeSafeYellow = "white";
      }
    },
    mouseEffectIn2(color) {
      this.activeSafeBlue = color;
    },
    mouseEffectOut2(color) {
      if (this.typeOfSafe == "blue") {
        this.activeSafeBlue = "blue";
      } else {
        this.activeSafeBlue = "white";
      }
    },

    async bvnCheck2() {
      // let x = "{\"error\":\"BVN not found\"}";
      // let ret = x.replace('{\"error\":\"\"}"','');
      // let ret2 = x.replace('\"}','');
      // console.log("strip data", ret);

      this.validateLoader = true;
      if (this.bvn === "") {
        this.$store.commit("setApiWarning", "Enter BVN");
        this.validateLoader = false;
      }
      this.decryptData();
      if (this.decryptBvn === this.bvn) {
        this.validateLoader = false;
        this.validBvn = true;
        this.$store.commit("setApiSuccess", "Success");
        this.firstName = this.cookiesData.fname;
        this.lastName = this.cookiesData.lname;
        this.middleName = this.cookiesData.middleName;
        this.address = this.cookiesData.address;
        this.phone = this.cookiesData.phone;
        this.dob = this.cookiesData.dob;
        this.email = this.cookiesData.email;
        this.gender = this.cookiesData.gender;
        if (this.address == "" || this.address == null) {
          this.activateAddress = true;
        } else {
          this.activateAddress = false;
        }
      } else {
        try {
          await Api.postRequest(`bvncheckwithdojah`, { bvn: this.bvn })
            .then(res => {
              // console.log("New bvn check on init", res);
              this.validateLoader = false;
              if (res.data.success === "success") {
                this.$store.commit("setApiSuccess", "Success");
                this.validBvn = true;
                this.bvndata = res.data.data;
                // console.log("bvn data", this.bvndata);
                this.firstName = this.bvndata.first_name;
                this.lastName = this.bvndata.last_name;
                this.middleName = this.bvndata.middle_name;
                this.address = this.bvndata.residential_address;
                this.phone = this.bvndata.phone_number1;
                this.dob = this.bvndata.date_of_birth;
                this.email = this.user.email;
                this.gender = this.bvndata.gender;
                if (
                  this.bvndata.middle_name == "" ||
                  this.bvndata.middle_name == null
                ) {
                  this.middleName = "-";
                } else {
                  this.middleName = this.bvndata.middle_name;
                }
                if (
                  this.bvndata.residential_address == "" ||
                  this.bvndata.residential_address == null
                ) {
                  this.activateAddress = true;
                } else {
                  this.activateAddress = false;
                }
                this.encryptData();
                // if (this.bvndata.email == "" || this.bvndata.email == null) {
                //   this.activateInput = true;
                // } else {
                //   this.activateInput = false;
                // }
              }
              if (res.data.error) {
                this.validBvn = false;
                if (
                  res.data.error_data ==
                  '{"error":"Your balance is low, pls visit the dashboard to top up"}'
                ) {
                  this.$store.commit(
                    "setApiFailed",
                    "Can't check BVN now contact Shellta"
                  );
                }
                if (res.data.error_data == '{"error":"BVN not found"}') {
                  this.$store.commit("setApiFailed", "BVN not found");
                } else {
                  this.$store.commit("setApiFailed", res.data.error);
                }
              }
            })
            .catch(err => {
              console.log("err", err);
              this.validBvn = false;
              this.diableBvnBtn = false;
              this.validateLoader = false;
            });
        } finally {
          // console.log(closed);
        }
      }
    },

    // $cookies.get("shelta-c");
    // console.log('Cookies', $cookies.get("shelta-c"));

    saveCookiesForBvn() {
      // const now = new Date();
      // const time = now.getTime();
      // const expireTime = time + 1000 * 36000;
      // now.setTime(expireTime);
      // document.cookie = "cookie=ok;expires='+now.toUTCString()+';path=/";
      const bvndata = {
        fname: this.bvndata.first_name,
        lname: this.bvndata.last_name,
        middleName: this.bvndata.middle_name,
        address: this.bvndata.residential_address,
        phone: this.bvndata.phone_number1,
        dob: this.bvndata.date_of_birth,
        email: this.user.email,
        gender: this.bvndata.gender,
        bvn: this.encData
      };

      if (this.bvndata.middle_name == "" || this.bvndata.middle_nam == null) {
        bvndata.middleName = "-";
      }
      const date = new Date();
      date.setDate(date.getDate() + 1);
      this.$cookies.set("_sheltaSafeData", bvndata, date);
      // console.log("new date", date);
    },

    async activateOrgansationSafe() {
      this.activateLoader2 = true;
      if (this.bizName === "" || this.bizEmail === "" || this.bizPhone === "") {
        this.$store.commit("setApiFailed", "All fields are required");
        this.activateLoader2 = false;
      } else {
        try {
          await Api.postRequest(`businessregisterforsafe`, {
            business_name: this.bizName,
            mobile_num: this.bizPhone,
            bvn: this.bvn,
            email: this.bizEmail
          })
            .then(res => {
              // console.log("res from org safe", res);
              this.activateLoader2 = false;
              this.validBvn = false;
              this.bvn = "";
              if (res.data.success) {
                this.sendMailForSafe();
                this.$store.commit("setApiSuccess", "success");
                localStorage.removeItem("safeType");
                localStorage.removeItem("upgradeSafe");
                this.$cookies.remove("_sheltaSafeData");
                this.$router.push({
                  path: `/dashboard/safe`
                });
              }
              if (res.data.error) {
                this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log("err", err);
              this.activateLoader2 = false;
              this.validBvn = false;
            });
        } finally {
          // console.log("closed")
        }
      }
    },

    async activateGoldSafe() {
      this.activateLoader = true;
      if (this.email == "" || this.email == null) {
        this.activateLoader = false;
        this.$store.commit("setApiFailed", "Email is required");
      }
      if (this.address == "") {
        this.$store.commit("setApiFailed", "Address is required");
        this.activateLoader = false;
      } else {
        try {
          await Api.postRequest(`${this.url}`, {
            first_name: this.firstName,
            last_name: this.lastName,
            middle_name: this.middleName,
            gender: this.gender,
            mobile_num: this.phone,
            email: this.email,
            dob: this.dob,
            address: this.address,
            bvn: this.bvn
          })
            .then(res => {
              // console.log("activate safe response", res);
              this.activateLoader = false;
              this.validBvn = false;
              this.bvn = "";
              if (res.data.success) {
                this.sendMailForSafe();
                this.$store.commit("setApiSuccess", "success");
                localStorage.removeItem("safeType");
                localStorage.removeItem("upgradeSafe");
                this.$cookies.remove("_sheltaSafeData");
                this.$router.push({
                  path: `/dashboard/safe`
                });
              }
              if (res.data.error) {
                this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log("err", err);
              this.activateLoader = false;
              this.validBvn = false;
            });
        } finally {
          // console.log(closed);
        }
      }
    },

    async activateBlueSafe() {
      // console.log("Clicked to activate");
      this.activateLoader = true;
      if (
        this.fname === "" ||
        this.fname == null ||
        this.lname === "" ||
        this.lname == null ||
        this.oname === "" ||
        this.oname == null ||
        this.naddress === "" ||
        this.naddress == null ||
        this.nemail === "" ||
        this.nemail == null ||
        this.nphone === "" ||
        this.nphone == null ||
        this.ndob === "" ||
        this.ndob == null ||
        this.ngender == "" ||
        this.ngender == null
      ) {
        this.activateLoader = false;
        this.$store.commit("setApiFailed", "All fields are required");
      }

      const today = new Date();
      const birthDate = new Date(this.ndob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();

      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 18) {
        this.activateLoader = false;
        this.$store.commit(
          "setApiFailed",
          "You can't be less than 18 years of age"
        );
      } else if (age > 18) {
        try {
          await Api.postRequest(`registerforsafe`, {
            first_name: this.fname,
            last_name: this.lname,
            middle_name: this.oname,
            gender: this.ngender,
            mobile_num: this.nphone,
            email: this.nemail,
            dob: this.ndob,
            address: this.naddress
          })
            .then(res => {
              // console.log("activate safe response", res);
              this.activateLoader = false;
              if (res.data.success) {
                this.sendMailForSafe();
                this.$store.commit("setApiSuccess", "success");
                localStorage.removeItem("safeType");
                this.$router.push({
                  path: `/dashboard/safe`
                });
              }
              if (res.data.error) {
                this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log("err", err);
              this.activateLoader = false;
            });
        } finally {
          // console.log(closed);
        }
      }
    },
    sendMailForSafe() {
      Api.postRequest(`sendsafewelcomemail`, {})
        .then(res => {
          // console.log('');
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$limeGreen: #3ec250;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

// Lato fonts

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"),
    url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-light";
  src: local("Lato-Light"),
    url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-bold-Italic";
  src: local("Lato-BlackItalic"),
    url("../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-thin-Italic";
  src: local("Lato-ThinItalic"),
    url("../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
}

// Raleway font
@font-face {
  font-family: "Raleway-Bold";
  src: local("Raleway-Bold"),
    url("../assets/fonts/Raleway/static/Raleway-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway";
  src: local("Raleway-Regular"),
    url("../assets/fonts/Raleway/static/Raleway-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway-italic";
  src: local("Raleway-italic"),
    url("../assets/fonts/Raleway/static/Raleway-Italic.ttf") format("truetype");
}

.safe_text_holder {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.safe_text {
  img {
    background: #3059eb;
    border-radius: 3px;
    padding: 5px;
  }
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: $black;
  span {
    color: #1765ca;
    padding-left: 5px;
  }
}
.projects_link {
  h6 {
    background: #3059eb;
    color: $secondary;
    padding: 5px 10px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    border-radius: 4px;
    cursor: pointer;
    img {
      padding-right: 5px;
    }
  }
}

.safe_type_holder {
  background: $secondary;
  border-radius: 10px;
  padding: 27px 40px;
}
.safe_holder_options {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 30px 15px 20px 15px;
  cursor: pointer;
  margin-bottom: 20px;
}
.orange {
  p {
    color: $yellow;
    padding-top: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
}
.blue {
  p {
    color: $primary;
    padding-top: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }
}

.blue:hover {
  background: $primary;
  p {
    color: $secondary !important;
  }
}
.orange:hover {
  background: $yellow;
  p {
    color: $secondary !important;
  }
}

.addedClassGold {
  background: $yellow;
  color: $secondary;
  p {
    color: $secondary;
  }
}

.addedClassBlue {
  background: $primary;
  color: $secondary;
  p {
    color: $secondary;
  }
}

.option_details {
  border-radius: 10px;
  padding: 27px 40px;
  border: 1px solid #e5e5e5;
  margin-top: 27px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bvn_input {
  margin: auto;
  input {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    height: 45.57px;
    // line-height: 16px;
    // color: #d9d9d9;
    color: $sheltaBlack;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin-bottom: 1rem;
    box-shadow: none;
    outline: 0;
  }
  label {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $black;
  }
  button {
    background: $yellow;
    color: $secondary;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 30px;
    box-shadow: none;
    outline: 0;
  }
}

.yellow_safe_user_data {
  label {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    color: $black;
  }
  input,
  select {
    height: 45.57px;
    background: $secondary;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    box-shadow: none;
    outline: 0;
  }
}

.activate_safe {
  margin: auto;
  button {
    position: relative;
    background: $yellow;
    color: $secondary;
    border: 1px solid $secondary;
    border-radius: 5px;
    max-width: 249.45px;
    width: 100%;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    box-shadow: none;
    outline: 0;
    img {
      position: relative;
      right: -20%;
      width: 28px;
    }
  }
  button:hover {
    background: #bb8713;
  }
}

.activate_safe2 {
  margin: auto;
  button {
    position: relative;
    background: $primary;
    color: $secondary;
    border: 1px solid $secondary;
    border-radius: 5px;
    max-width: 249.45px;
    width: 100%;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    box-shadow: none;
    outline: 0;
    img {
      position: relative;
      right: -20%;
      width: 28px;
    }
  }
  button:hover {
    background: #09227b;
  }
}

@media only screen and (max-width: 599px) {
  .flip_row {
    flex-wrap: nowrap;
  }
  .safe_type_holder {
    padding: 27px 0;
  }
  .option_details {
    padding: 27px 15px;
  }
}
</style>
